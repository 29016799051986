.btn-light-warning {
  background-color: #1b96bc !important;
  color: black !important;
}
.btn-light-warning:hover {
  background-color: #1b96bc !important;
}
.headerName {
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;
  padding: 5px;
  font-weight: 500;
}
.headerName1 {
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;
  padding: 5px;
  font-weight: 500;
  margin-left: 70px !important;
}
.headerName2 {
  border: 1px solid white;
  border-radius: 5px;
  background-color: white;
  margin-top: 10px;
  padding: 5px;
  font-weight: 500;
}
.headerlabel {
  text-transform: uppercase;
  color: #f21f5e;
  font-weight: 500;
}
.headerbackground {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;
  height: 110px !important;
}
.logowidth {
  margin-left: 100px !important;
}
/* Media query for tablet view (768px to 991px) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-component {
    background-color: #c6e5ef !important;
    margin-top: -55px;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
}

/* Media query for desktop view (992px and above) */
@media only screen and (min-width: 992px) {
  .your-component {
    background-color: #c6e5ef !important;
  }
  .headerName1 {
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    margin-top: 10px;
    padding: 5px;
    font-weight: 500;
    margin-left: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1025px) {
  .headerName1 {
    border: 1px solid white;
    border-radius: 5px;
    background-color: white;
    margin-top: 10px;
    padding: 5px;
    font-weight: 500;
    margin-left: 20px !important;
  }
}

.claimantsidemenu {
  margin-left: 80px !important;
}
.claimantsidemenu1 {
  margin-left: 40px !important;
}
.claimantsidemenu1old {
  margin-left: 20px !important;
}

@media only screen and (max-width: 500px) {
  .subheader1 {
    margin-top: -110px;
    height: 160px;
  }
  .claimantsidemenu1 {
    margin-left: 4% !important;
    margin-top: 109px;
  }
  .claimantsidemenu1old {
    margin-left: 10% !important;
  }
  .mobiledisplaynone {
    display: none;
  }
}

@media only screen and (min-width: 501px) and (max-width: 900px) {
  .subheader1 {
    margin-top: -60px;
    height: 160px;
  }
  .mobiles {
    position: relative;
    margin-top: 50px;
    margin-right: 50px;
    /* margin-right: 50px; */
  }
}
@media only screen and (min-width: 900px) and (max-width: 1400px) {
  .subheader1 {
    margin-top: -60px;
    height: 160px;
  }
  .headerName2{
    margin-left: 120px !important;
  }
}
.mobiles {
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
}
@media only screen and (min-width: 1401px) {
  .subheader1 {
    margin-top: -60px;
    height: 160px;
  }
  .headerName2{
    margin-left: 180px !important;
  }
}

.claimant_card {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

@media only screen and (max-width: 480px) {
    .your-component {
    background-color: #c6e5ef !important;
    height: 200px;
  }
  .claimant_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerName1{
  margin-left: 22px !important;
  margin-bottom: 20px;
}
.header-mobile-fixed .wrapper {
  padding: 0px !important;
}
}
