//
// Switch
//

@mixin switch-size(
  $margin,
  $width,
  $height,
  $line-height,
  $space,
  $checked-tick-pos,
  $icon-font-size
) {
  input:empty ~ span {
    //line-height: $line-height;
    margin: $margin;
    height: 24px;
    width: 20px + $space;
    border-radius: ($height/2);
  }

  input:empty ~ span:before{
    width: 46px;
    border-radius: ($height/2);
    
  }
  input:empty ~ span:after {
    width: 24px;
    border-radius: ($height/2);
    
  }

  input:empty ~ span:after {
    height: 22px - 2 * $space;
    width: 22px - 2 * $space;
    //line-height: $line-height;
    top: $space;
    bottom: $space;
    margin-left: $space;
    font-size: $icon-font-size;
    text-align: center;
    vertical-align: middle;
    
   
  }
  input:empty~span:after{
    height: 22px - 2 * $space;
    width: 22px - 2 * $space;
    //line-height: $line-height;
    top: $space;
    bottom: $space;
    left: -1px;;   
    margin-left: $space;
    font-size: $icon-font-size;
    text-align: center;
    vertical-align: middle;

  }

  input:checked ~ span:after {
    margin-left: $checked-tick-pos;
   
  }
//   input:checked ~ span:before{
//     border: 2px solid red;
//   } 
}

@mixin switch-default-theme(
  $base,
  $tick,
  $checked-base,
  $checked-tick-bg,
  $checked-tick-color
) {
  // Base
  input:empty ~ span:before {
    background-color: white;
    border: 2px solid #1B96BC;
    // border-width: 10px solid red !important;
  }

  // Tick
  input:empty ~ span:after {
    background-color:  #1B96BC;

   
  }

  input:checked {
    // Base
    ~ span:before {
      background-color: $checked-base;
      border: none !important;
    }

    // Tick
    ~ span:after {
      opacity: 1;
      color: $checked-tick-color;
     
      background-color: $checked-tick-bg;
    }
  }
}

@mixin switch-outline-theme(
  $outline,
  $tick-bg,
  $checked-outline,
  $checked-tick-bg,
  $checked-tick-color
) {
  base input:empty ~ span:before {
    border: 2px solid $outline;
    background-color: transparent;
  }

  // Tick
  input:empty ~ span:after {
    background-color: $tick-bg;
  }

  input:checked {
    // Base
    ~ span:before {
      border: 2px solid $checked-outline;
      background-color: transparent;
    }

    // Tick
    ~ span:after {
      color: $checked-tick-color;
      background-color: $checked-tick-bg;
    }
  }
}
