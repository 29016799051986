.sign {
  width: 93%;
}
.sign1 {
  width: 70%;
}

.forgot {
  float: right;
  margin-right: 20px;
  margin-top: -20px;
}

.forgot1 {
  float: right;
  margin-right: 0px;
  margin-top: -20px;
}
.loginbackgroundImage {
  background-image: url(../../../../Image/desktoplogin.png);
  background-size: 100% auto;
}

@media only screen and (max-width: 1200px) {
  .loginbackgroundImage{
    background-image: url(../../../../Image/logintab.png);
    background-size: 100% auto;
  }
}

.custom-overlay {
    background-color: blue; /* Set the desired background color */
  }

  .tooltip-inner{
    height: 28px ;
    color: white !important;
    background-color:#DE3030 !important;
  }


  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color:#DE3030 !important;
}

