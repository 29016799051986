@media (min-width: 608px) and (max-width: 992px) {
  .col-lg-3.col-md-4.col-sm-4.collapsed {
    display: none;
  }

  .col-lg-3.col-md-4.col-sm-4.showblock {
    display: "block";
    height: "100%";
    position: absolute;
    z-index: 2;
    height: 100%;
  }
  .masteralign {
    margin-top: 100px !important;
  }
  .schedcardview {
    margin-top: 20px !important;
  }
}

.sbuttonss {
  display: "flex";
  position: relative;
  z-index: 1;
  justify-content: left;
  align-items: left;
  margin-left: -300px;
}
@media (min-width: 1640px) and (max-width: 2500px) {
  .max {
    margin-top: 150px !important;
  }
}

@media (min-width: 2500px) {
  .max {
    margin-top: 150px !important;
  }
}

@media (max-width: 1200px) {
  /* .togglebutton{
    display:none !important;
  } */
}

ul {
  list-style-type: none !important;
}

.ullist {
  list-style-type: square !important;
}

/* .sidebar{
  width:"500px"
} */
.ndoctor {
  text-align: center !important;
}

/* ul li .fa-caret-down{
  display:flex !important;
  justify-content: right !important;
  align-items: right !important;
} */

.usercomponts {
  display: flex;
  flex-wrap: wrap;
}

.user-card {
  width: 95px;
  height: 80px;
  border: 2px solid #1b96bc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 13px 10px 5px 10px;
}

.user-card:hover {
  background-color: #e3e3e3;
}

.onet:active {
  background-color: green;
}

.spans {
  text-align: center !important;
}

.usercomponts1 {
  display: flex;
  flex-wrap: wrap;
}

.user-card1 {
  width: 100px;
  height: 90px;
  border: 2px solid #1b96bc;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin: 10px;
  padding: 10px;
}

.user-card1:hover {
  background-color: #e3e3e3;
}

.usercard:hover {
  background-color: #e3e3e3;
}

body {
  background-color: #ededed !important;
}

.active > div.user-card {
  background-color: #d4e8ff;
}

@media only screen and (min-width: 608px) and (max-width: 992px) {
  .dashboard5 {
    margin-top: 10px !important;
  }

  .dashboard {
    margin-top: 50px !important;
    width: "100%";
    box-shadow: 0px 0px 3px 1px #bbbbbb;
    background-color: white;
    height: 40px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .docdashboard {
    margin-top: 38px !important;
    width: "100%";
    box-shadow: 0px 0px 3px 1px #bbbbbb;
    background-color: white;
    height: 40px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .doctorpadding {
    margin-top: 30px;
  }
  .navbar {
    width: 300px;
  }
  .navbar1 {
    height: 500px !important;
    margin-left: 37px;
    border: 2px solid #1b96bc;
    border-radius: 10px;
    float: left;
  }
  .drop {
    height: 40px;
    margin-top: 30px !important;
  }
  .menubar {
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
  .dashboard5 {
    margin-left: -2px;
  }
  .dashboard {
    background-color: white;
    height: 40px;
    margin-bottom: -25px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .docdashboard {
    background-color: white;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    margin-top: -100px;
    width: 330px;
  }

  .bars {
    margin-top: 0px;
  }

  .navbar1 {
    margin-right: 50px;
    width: 800px;
  }
}

@media only screen and (min-width: 1301px) and (max-width: 2000px) {
  .dashboard {
    background-color: white;
    height: 40px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .docdashboard {
    background-color: white;
    height: 40px;
    margin-bottom: -20px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    margin-top: -100px;
    width: 360px;
  }

  .bars {
    margin-top: 0px;
  }

  .navbar1 {
    margin-right: 110px;
    width: 800px !important;
  }

  .dashboard5 {
    margin-left: -50px;
  }
}

@media only screen and (min-width: 2001px) {
  .navbar1 {
    width: 1000px;
  }

  .dashboard {
    background-color: white;
    height: 40px;
    margin-top: 70px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .docdashboard {
    background-color: white;
    height: 40px;
    margin-bottom: -20px;
    border-radius: 10px;
    box-shadow: 0 0 2px 1px #bbbbbb;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar {
    margin-top: -100px;
  }

  .dashboard5 {
    margin-left: -50px !important;
    margin-top: -60px !important;
  }
}

.text-container {
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.clicked {
  background-color: #1b96bc;
  height: 35px;
  color: white !important;
  border-radius: 5px;
  width: 220px;
}

.clickedmenu {
  background-color: #1b96bc;
  height: 35px;
  color: white !important;
  border-radius: 5px;
  width: 232px;
}

.imagesize {
  width: 32px;
  height: 22px;
}

@media only screen and (max-width: 992px) {
  .doctorpadding {
    margin-top: 30px;
  }
  .claimantsidemenu {
    margin-left: 55px !important;
  }
}
